<template>
  <div class="flex flex-col">
    <div
      class="sticky top-0 bg-white z-20 px-6 default-transition"
      v-scroll-shadow
    >
      <div class="flex sm:items-center flex-col sm:flex-row sm:justify-between">
        <div class="space-x-4 flex items-center">
          <div class="text-xl font-semibold py-5">
            Reviews
          </div>
          <div class="flex items-center text-sm text-gray-400 space-x-2">
            <div class="w-24">
              <progress-bar :max="maxAllowance" :current="allowance" />
            </div>
            <div>{{ allowance }} / {{ maxAllowance }}</div>
          </div>
        </div>
        <div class="flex items-center space-x-4">
          <div v-if="selectedReviews.length > 0" class="text-gray-500">
            {{ selectedReviews.length }}
            {{ selectedReviews.length > 1 ? 'items' : 'item' }} selected
          </div>
          <remove-review-btn
            :selected-reviews="selectedReviews"
            v-if="reviews && reviews.length > 0 && selectedReviews.length > 0"
          />
          <form @submit.prevent="toggleModal">
            <loading-button
              :is-loading="false"
              :is-enabled="selectedReviews.length > 0"
              v-if="reviews && reviews.length > 0"
            >
              Send reviews
            </loading-button>
          </form>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col flex-grow mt-6 sm:mt-0 px-6  ">
      <loading :is-loading="isLoading" is-full class="flex-grow flex flex-col">
        <transition name="fade" mode="out-in">
          <div
            class="w-full overflow-x-auto"
            v-if="reviews && reviews.length > 0"
            key="results-users"
          >
            <div class="grid grid-template   whitespace-nowrap   ">
              <div class=" table-head contents">
                <div class="flex justify-center">
                  <input
                    type="checkbox"
                    :disabled="allowanceReached"
                    v-model="selectAll"
                  />
                </div>
                <div></div>
                <div>Email</div>

                <div>Added</div>
                <div></div>
              </div>
              <div
                v-for="(item, index) in reviews"
                :key="`item-${index}`"
                class="group flex items-center py-2 hover:bg-gray-50 contents table-item "
                :class="{ 'is-selected': item.isSelected }"
              >
                <div class="flex justify-center">
                  <input
                    type="checkbox"
                    :disabled="allowanceReached || !canSend(item)"
                    v-model="item.isSelected"
                  />
                </div>

                <router-link
                  tag="div"
                  :to="{
                    name: 'userProfile',
                    params: { id: item.userid, tab: 'activity' }
                  }"
                  class="cursor-pointer group flex items-center py-2 hover:bg-gray-50 contents  "
                >
                  <div class="flex items-center px-2">
                    <avatar size="sm" :email="item.name" class="mr-2" />

                    {{ item.name }}
                  </div>
                </router-link>
                <div class="text-gray-500">{{ item.email }}</div>

                <div class="text-gray-500">
                  {{ item.date | timeago }}
                </div>
                <div @click="toggleFlag(item)">
                  <font-awesome-icon
                    :icon="[item.hold ? 'fad' : 'fal', 'flag-swallowtail']"
                    class="cursor-pointer"
                    :class="
                      item.hold
                        ? 'text-red-400'
                        : 'text-gray-500 hover:text-red-400'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            key="no-results-reviews"
            class="flex-grow h-full flex items-center justify-center text-gray-400"
          >
            <div>
              All review requests have been sent
            </div>
          </div>
        </transition>
      </loading>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="send-reviews"
      :modal-title="
        `Send ${selectedReviews.length}
      ${selectedReviews.length > 1 ? 'reviews' : 'review'}`
      "
    >
      <p>
        You are about to send {{ selectedReviews.length }}
        {{ selectedReviews.length > 1 ? 'reviews' : 'review' }} to
      </p>
      <div class="divide-y mt-4 border rounded">
        <div
          v-for="review in selectedReviews"
          class="flex items-center py-2 space-x-4 px-3"
          :key="`review-${review.userid}`"
        >
          <div class="flex-grow">{{ review.name }}</div>
          <div class="text-gray-400">{{ review.email }}</div>
          <div
            :class="{
              'text-green-500': review.sendStatus === 'Sent',
              'text-red-500': review.sendStatus === 'Failed'
            }"
          >
            <div class="w-6 flex items-center justify-center">
              <font-awesome-icon
                v-if="review.sendStatus"
                :icon="[
                  'fal',
                  review.sendStatus === 'Sent' ? 'check' : 'xmark'
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex  space-x-4 mt-6" v-if="!allSent">
        <form @submit.prevent="sendReviews">
          <loading-button
            :is-loading="sendLoading"
            :label="
              `Yes, send ${selectedReviews.length}
      ${selectedReviews.length > 1 ? 'reviews' : 'review'}`
            "
          />
        </form>
        <form @submit.prevent="toggleModal">
          <loading-button label="Cancel" secondary />
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Avatar from '@/components/common/ui/Avatar';
import { filter, uniq } from 'lodash/fp';
import Loading from '@/components/common/ui/Loading';
import LoadingButton from '@/components/common/ui/LoadingButton';
import utils from '@/utils';
import Modal from '@/components/common/ui/Modal';
import ProgressBar from '@/components/common/ui/ProgressBar';
import RemoveReviewBtn from '@/components/common/ui/reviews/RemoveReviewBtn';
import moment from 'moment';
export default {
  components: {
    Avatar,
    ProgressBar,
    Loading,
    LoadingButton,
    Modal,
    RemoveReviewBtn
  },
  data() {
    return {
      isLoading: true,
      selectAll: false,
      sendLoading: false,
      allSent: false
    };
  },
  watch: {
    selectAll(newValue) {
      this.reviews.forEach(i => {
        if (this.canSend(i)) {
          this.$set(i, 'isSelected', newValue);
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      reviews: 'reviewsGetter',
      allowance: 'reviewAllowanceGetter',
      maxAllowance: 'reviewMaxGetter',
      modalVisible: 'getModalVisible'
    }),

    selectedReviews() {
      if (!this.allowanceReached) {
        const filtered = filter(['isSelected', true], this.reviews);
        const arr = [];
        filtered.forEach(r => {
          arr.push(r);
        });
        return uniq(arr);
      }
      return [];
    },

    allowanceReached() {
      return this.allowance >= this.maxAllowance;
    }
  },
  methods: {
    ...mapActions({
      flagReview: 'flagReview'
    }),
    canSend(item) {
      const diff = moment().diff(item.date, 'days');

      return diff > 2 && !item.hold;
    },
    toggleFlag(item) {
      this.flagReview({
        uid: item.userid,
        data: item.hold ? !item.hold : true
      }).then(() => {
        item.hold = item.hold ? !item.hold : true;
      });
    },
    toggleModal() {
      if (this.modalVisible) {
        this.$store.dispatch('getReviews').then(() => {
          utils.toggleModal();
        });
      } else {
        utils.toggleModal();
      }
    },
    sendReviews() {
      this.sendLoading = true;
      const promises = [];

      this.selectedReviews.forEach(r => {
        r.sendStatus = null;
        promises.push(
          this.$store
            .dispatch('sendReview', r.userid)
            .then(() => {
              r.sendStatus = 'Sent';
            })
            .catch(() => {
              r.sendStatus = 'Failed';
            })
        );
      });
      Promise.allSettled(promises)
        .then(() => {
          this.sendLoading = false;
          this.allSent = true;
        })
        .catch(error => {
          this.sendLoading = false;
          console.log(`Error in executing ${error}`);
        });
    }
  },
  mounted() {
    this.$store.dispatch('getReviews').then(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style lang="css" scoped>
.grid-template {
  grid-template-columns: 0.15fr 5fr 2fr 1fr 0.15fr;
}
</style>
