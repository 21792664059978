var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.toggleModal.apply(null, arguments)}}},[_c('loading-button',{attrs:{"warning":"","is-enabled":_vm.selectedReviews.length > 0}},[_vm._v(" Remove ")])],1),_c('modal',{attrs:{"open":_vm.modalVisible,"on-close":_vm.toggleModal,"id":"remove-reviews","modal-title":`Remove ${_vm.selectedReviews.length}
    ${_vm.selectedReviews.length > 1 ? 'users' : 'user'}`}},[_c('p',[_vm._v(" You are about to remove "+_vm._s(_vm.selectedReviews.length)+" "+_vm._s(_vm.selectedReviews.length > 1 ? 'users' : 'user')+" ")]),_c('div',{staticClass:"divide-y mt-4 border rounded"},_vm._l((_vm.selectedReviews),function(review){return _c('div',{key:`review-${review.userid}`,staticClass:"flex items-center py-2 space-x-4 px-3"},[_c('div',{staticClass:"flex-grow"},[_vm._v(_vm._s(review.name))]),_c('div',{staticClass:"text-gray-400"},[_vm._v(_vm._s(review.email))]),_c('div',{class:{
            'text-green-500': review.sendStatus === 'Sent',
            'text-red-500': review.sendStatus === 'Failed'
          }},[_c('div',{staticClass:"w-6 flex items-center justify-center"},[(review.sendStatus)?_c('font-awesome-icon',{attrs:{"icon":[
                'fal',
                review.sendStatus === 'Sent' ? 'check' : 'xmark'
              ]}}):_vm._e()],1)])])}),0),(!_vm.allRemoved)?_c('div',{staticClass:"flex space-x-4 mt-6"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.removeReviews.apply(null, arguments)}}},[_c('loading-button',{attrs:{"is-loading":_vm.removeLoading,"warning":"","label":`Yes, remove ${_vm.selectedReviews.length}
    ${_vm.selectedReviews.length > 1 ? 'users' : 'user'}`}})],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.toggleModal.apply(null, arguments)}}},[_c('loading-button',{attrs:{"label":"Cancel","secondary":""}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }