<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button warning :is-enabled="selectedReviews.length > 0">
        Remove
      </loading-button>
    </form>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="remove-reviews"
      :modal-title="
        `Remove ${selectedReviews.length}
      ${selectedReviews.length > 1 ? 'users' : 'user'}`
      "
    >
      <p>
        You are about to remove {{ selectedReviews.length }}
        {{ selectedReviews.length > 1 ? 'users' : 'user' }}
      </p>
      <div class="divide-y mt-4 border rounded">
        <div
          v-for="review in selectedReviews"
          class="flex items-center py-2 space-x-4 px-3"
          :key="`review-${review.userid}`"
        >
          <div class="flex-grow">{{ review.name }}</div>
          <div class="text-gray-400">{{ review.email }}</div>
          <div
            :class="{
              'text-green-500': review.sendStatus === 'Sent',
              'text-red-500': review.sendStatus === 'Failed'
            }"
          >
            <div class="w-6 flex items-center justify-center">
              <font-awesome-icon
                v-if="review.sendStatus"
                :icon="[
                  'fal',
                  review.sendStatus === 'Sent' ? 'check' : 'xmark'
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex  space-x-4 mt-6" v-if="!allRemoved">
        <form @submit.prevent="removeReviews">
          <loading-button
            :is-loading="removeLoading"
            warning
            :label="
              `Yes, remove ${selectedReviews.length}
      ${selectedReviews.length > 1 ? 'users' : 'user'}`
            "
          />
        </form>
        <form @submit.prevent="toggleModal">
          <loading-button label="Cancel" secondary />
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';

import Modal from '@/components/common/ui/Modal';
export default {
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      removeLoading: false,

      allRemoved: false
    };
  },
  components: {
    LoadingButton,
    Modal
  },
  props: {
    selectedReviews: {
      type: Array,
      reqiured: true
    }
  },
  methods: {
    toggleModal() {
      if (this.modalVisible) {
        this.$store.dispatch('getReviews').then(() => {
          this.modalVisible = false;
        });
      } else {
        this.modalVisible = true;
      }
    },
    removeReviews() {
      this.removeLoading = true;
      const promises = [];

      this.selectedReviews.forEach(r => {
        r.sendStatus = null;
        promises.push(
          this.$store
            .dispatch('removeReview', r.userid)
            .then(() => {
              r.sendStatus = 'Sent';
            })
            .catch(() => {
              r.sendStatus = 'Failed';
            })
        );
      });
      Promise.allSettled(promises)
        .then(() => {
          this.removeLoading = false;
          this.allRemoved = true;
        })
        .catch(error => {
          this.removeLoading = false;
          console.log(`Error in executing ${error}`);
        });
    }
  }
};
</script>

<style></style>
