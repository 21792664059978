<template>
  <div
    class="w-full  rounded-full overflow-hidden relative h-2 flex-none"
    :class="`bg-gray-100`"
  >
    <div
      class=" absolute default-transition left-0 h-full rounded-full min-w-2 "
      :class="`bg-indigo-400`"
      :style="styleObj"
    ></div>
  </div>
</template>

<script>
import utils from '@/utils';
export default {
  props: {
    current: {
      type: Number
    },
    max: {
      type: Number
    }
  },
  computed: {
    percentage() {
      return utils.getPercentageOf(this.max, this.current).toFixed();
    },
    styleObj() {
      return {
        width: `${utils.getPercentageOf(this.max, this.current)}%`
      };
    }
  }
};
</script>

<style></style>
