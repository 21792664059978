<template>
  <div
    :id="id"
    class="modal-wrapper fixed z-50 overflow-y-auto flex inset-0 justify-center overscroll-none"
    @click.stop="handleClose"
  >
    <transition enter-active-class="miniFadeInUp" mode="out-in">
      <div v-if="animateIn">
        <div
          class="modal bg-white z-50 rounded-lg shadow-xl print:shadow-none flex flex-col leading-normal my-16"
          :class="`size-${size}`"
          @click.stop
        >
          <div
            class="px-5 py-5 print:pt-0 flex justify-between bg-white items-center rounded-tl-lg rounded-tr-lg sticky top-0 z-10 print:px-0"
          >
            <div class="flex-grow mr-4">
              <div class="text-xl font-semibold">
                {{ modalTitle }}
              </div>
            </div>
            <button
              class="text-gray-400 print:hidden"
              tabindex="-1"
              @click.stop="handleClose"
            >
              <font-awesome-icon :icon="['fal', 'xmark']" class="text-2xl" />
            </button>
          </div>
          <div class="px-5 pb-5 print:pb-0 print:px-0">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      default: 'modal-wrapper'
    },
    open: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    modalTitle: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'sm'
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      animateIn: false,
      firstLoad: true,
      doc: null,
      modal: null
    };
  },
  methods: {
    toggleVisibility() {
      this.doc.classList.toggle('bring-forward');
      this.modal.classList.toggle('show-modal');
      document.body.classList.toggle('overflow-hidden');
      this.animateIn = !this.animateIn;
    },
    handleClose() {
      this.onClose();
    }
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (e.keyCode == 27 && this.open) {
        this.handleClose();
      }
    });
    if (this.firstLoad) {
      this.doc = document.querySelector('#app');
      this.modal = document.querySelector(`#${this.id}`);
      this.doc.appendChild(this.modal);

      this.firstLoad = false;
    }
  },
  watch: {
    open() {
      this.toggleVisibility();
    }
  },
  destroyed() {
    const modal = document.querySelector(`#${this.id}`);

    modal.remove();
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  background-color: rgba(26, 28, 51, 0.5);

  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.modal {
  min-width: 380px;

  width: auto;

  &.size-xxs {
    width: auto;
    max-width: 380px;
  }
  &.size-xs {
    width: auto;
    max-width: 520px;
  }
  &.size-sm {
    width: auto;
    min-width: 640px;
  }
  &.size-md {
    width: auto;
    min-width: 860px;
  }
  &.size-lg {
    width: auto;
    min-width: 90%;
  }
}

.modal-header {
  background-color: rgba(255, 255, 255, 0.8);
}

.show-modal {
  opacity: 1;
  visibility: visible;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.bring-forward {
  z-index: 999;
}
</style>
